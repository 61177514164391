<template>
  <div class="sidebar open">
    <a href="/" class="nav-link">
      <div class="logo-details">
        <!-- <img
          :src="logo"
          class=""
          style="width: 51px; margin-top: 3px; margin-left: 1px"
          alt="profileImg"
        /> -->
        <div class="logo_name mr-2 ms-2">WECCO</div>
      </div></a
    >
    <ul>
      <li>
        <i
          class="bx bx-chevron-right toggle-icon bx-tgl"
          style="
            right: -24px;
            top: 0px;
            height: 30px;
            min-width: 30px !important;
            line-height: 33px;
          "
          @click="toggleOpenClose()"
        ></i>
        <input
          style="visibility: hidden"
          type="text"
          placeholder="Search..."
          autocomplete="none"
        />
      </li>
    </ul>

    <ul class="ul-list">
      <li>
        <a href="/travel">
          <i class="bx bx-grid-alt"></i>
          <span class="links_name"> Travel History </span>
        </a>
        <span class="tooltip">Travel History</span>
      </li>

      <li>
        <a href="/">
          <i class="bx bx-calendar"></i>
          <span class="links_name"> Booking </span>
        </a>
        <span class="tooltip">Booking</span>
      </li>

      <li>
        <a href="/profile">
          <i class="bx bx-user-plus"></i>
          <span class="links_name"> Profile</span>
        </a>
        <span class="tooltip">Profile</span>
      </li>

      <!-- <li>
        <a href="#">
          <i class="bx bx-log-out"></i>
          <span class="links_name"> Logout </span>
        </a>
        <span class="tooltip">Logout</span>
      </li> -->
    </ul>
  </div>
</template>

<script>
import logo from "../../../assets/MyPorterLogo/Small1.svg";
import ButtonOne from "../../../assets/Icons/ButtonOne.svg";
import ButtonTwo from "../../../assets/Icons/ButtonTwo.svg";
import ButtonThree from "../../../assets/Icons/ButtonThree.svg";
import ButtonFour from "../../../assets/Icons/ButtonFour.svg";
import ButtonUser from "../../../assets/Icons/IconUser.svg";

export default {
  name: "NavBar",
  data: function () {
    return {
      logo,
      ButtonOne,
      ButtonTwo,
      ButtonThree,
      ButtonFour,
      ButtonUser,
      toggleValue: false,
    };
  },
  mounted() {
    $(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });
  },
  methods: {
    toggleOpenClose() {
      if (this.toggleValue == false) {
        var sidebar = document
          .querySelector(".sidebar")
          ?.classList.toggle("open");
        var sidebar = document
          .querySelector(".mp-content")
          ?.classList.toggle("closed");
        this.menuBtnChange();
        this.toggleValue = !this.toggleValue;
        if (this.router.url == "/") {
        }
      } else {
        var sidebar = document
          .querySelector(".sidebar")
          ?.classList.toggle("open");
        var sidebar = document
          .querySelector(".mp-content")
          ?.classList.toggle("closed");
        if (this.router.url == "/") {
        }
        this.menuBtnChange();
        this.toggleValue = !this.toggleValue;
      }
    },
  },
  created() {
    const activeElement = document.getElementsByClassName("activeBorder");

    setTimeout(() => {
      if (activeElement[0] !== undefined) {
        const parentDiv = activeElement[0].parentElement;
        parentDiv.classList.add("activeBorderStyle");
      }
    }, 500);
  },
};
</script>

<style scoped>
.activeBorderStyle {
  width: 36px;
  height: 36px;
  border-radius: 15px;
  background: #267a29;
  text-align: center;
}

.nav-item {
  height: 40px;
}

.navLinksDiv:hover {
  width: 36px;
  height: 36px;
  border-radius: 15px;
  background: #267a29;
  text-align: center;
}

.navDivSec {
  position: absolute;
  right: 0 !important;
}

#navDiv {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 0;
  bottom: 0;
  width: 50px;
  height: 100%;
  background-color: #000080;
}
.bx-tgl {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  font-size: 22px;
  background: #d1eed2;
  color: #000080 !important;
  cursor: pointer;
}
/* .open .bx-tgl:hover {
  background: #d1eed2;
  color: #006203 !important;
} */

.open .toggle-icon {
  transform: rotate(180deg);
  top: -10px !important;
}

.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 78px;
  background: #000080;
  padding: 6px 14px;
  z-index: 9999;
  transition: all 0.5s ease;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  /* overflow-y: auto !important;
  overflow-x: hidden !important; */
}
.sidebar ul {
  margin: 0;
  padding: 0px 8px 0px 8px;
}
.ul-list {
  overflow-y: auto !important;
  overflow-x: hidden !important;
  height: 100% !important;
  padding-bottom: 200px !important;
}
::-webkit-scrollbar {
  width: 0px !important;
  height: 0px !important;
}
.sidebar.open {
  width: 250px;
  z-index: 999;
}
.logo-details {
  height: 60px;
  display: flex;
  align-items: center;
  position: relative;
}
.sidebar .logo-details .icon {
  opacity: 0;
  transition: all 0.5s ease;
}
.toggle-icon {
  visibility: hidden;
}
@media only screen and (max-width: 1024px) {
  .toggle-icon {
    visibility: visible !important;
    color: #ffffff !important;
    z-index: 999;
  }
}
.sidebar .logo-details .logo_name {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  opacity: 0;
  transition: all 0.5s ease;
}
.sidebar.open .logo-details .icon,
.sidebar.open .logo-details .logo_name {
  opacity: 1;
}
.sidebar .logo-details #btn {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  font-size: 22px;
  transition: all 0.4s ease;
  font-size: 23px;
  text-align: center;
  cursor: pointer;
  transition: all 0.5s ease;
}
.sidebar.open .logo-details #btn {
  text-align: right;
}
.sidebar i {
  color: #fff;
  height: 60px;
  min-width: 50px;
  font-size: 28px;
  text-align: center;
  line-height: 60px;
}

::-webkit-scrollbar {
  height: 2px;
  width: 2px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.sidebar .nav-list {
  margin-top: 20px;
  height: 100%;
  padding-bottom: 200px;
  overflow-y: scroll;
}
.sidebar li {
  position: relative;
  margin: 8px 0;
  list-style: none;
}
.sidebar li .tooltip {
  position: absolute;
  top: -20px;
  left: calc(100% + 15px);
  z-index: 3;
  background: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 400;
  opacity: 0;
  white-space: nowrap;
  pointer-events: none;
  transition: 0s;
}
.sidebar li:hover .tooltip {
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
  top: 50%;
  transform: translateY(-50%);
}
.toggle-icon {
  visibility: visible;
}

.sidebar input {
  font-size: 15px;
  color: #fff;
  font-weight: 400;
  outline: none;
  height: 50px;
  width: 100%;
  width: 50px;
  border: none;
  border-radius: 12px;
  transition: all 0.5s ease;
  background: #000080;
}
.sidebar.open input {
  padding: 0 20px 0 50px;
  width: 100%;
}
/* .sidebar .bx-search {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  font-size: 22px;
  background: #006203;
  color: #fff;
}
.sidebar.open .bx-search:hover {
  background: #006203;
  color: #fff;
}
.sidebar .bx-search:hover {
  background: #fff;
  color: #006203;
} */
.sidebar li a {
  display: flex;
  height: 100%;
  width: 100%;
  border-radius: 12px;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
  background: #000080;
  margin: 0;
  padding: 0;
}
.sidebar li a:hover {
  background: #fff;
}
.sidebar li a .links_name {
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  transition: 0.4s;
}
.links-name-active {
  color: #fff;
  font-size: 25px;
  font-weight: 700 !important;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  transition: 0.4s;
}
.sidebar.open li a .links_name {
  opacity: 1;
  pointer-events: auto;
}

.sidebar li a:hover .links_name,
.sidebar li a:hover i {
  transition: all 0.5s ease;
  color: #006203;
}
.sidebar li i {
  height: 50px;
  line-height: 50px;
  /* font-size: 18px; */
  border-radius: 36px;
}

.sidebar li img {
  height: 26px;
  width: 26px;
  object-fit: cover;
  border-radius: 6px;
  margin-right: 10px;
}
.sidebar li.profile .name,
.sidebar li.profile .job {
  font-size: 15px;
  font-weight: 400;
  color: #fff;
  white-space: nowrap;
  margin-left: 16px;
}
.sidebar li.profile .job {
  font-size: 12px;
}
.sidebar .profile #log_out {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background: #000080;
  width: 100%;
  height: 60px;
  line-height: 60px;
  border-radius: 0px;
  transition: all 0.5s ease;
}
.sidebar.open .profile #log_out {
  width: 50px;
  background: none;
}
.home-section {
  position: relative;
  background: #e4e9f7;
  min-height: 100vh;
  top: 0;
  left: 78px;
  width: calc(100% - 78px);
  transition: all 0.5s ease;
  z-index: 2;
}
.sidebar.open ~ .home-section {
  left: 250px;
  width: calc(100% - 250px);
}
.home-section .text {
  display: inline-block;
  color: #006203;
  font-size: 25px;
  font-weight: 500;
  margin: 18px;
}
.logout-link:hover {
  cursor: pointer;
}

@media all and (max-device-width: 425px) {
  #navDiv {
    width: 50px;
  }
}

.navList {
  display: flex;
  justify-content: center;
}

@media all and (max-device-width: 768px) {
  #navDiv {
    width: 50px;
  }
  #travelImg {
    margin-top: 10px !important;
  }
}

#travelImg {
  margin-top: 10px !important;
}

.navList {
  display: flex;
  justify-content: center;
}

/* .btnOne {
  border-radius: 13px;
} */
</style>
